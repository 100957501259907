'use strict';

angular.module('app', [
    //Templates cache
    'templates',

    // Third party modules
    'ui.router',
    'ui.grid',
    'ui.grid.edit',
    'ui.bootstrap',
    'ngSanitize',
    'ngAnimate',
    'js-data',
    'highcharts-ng',
    'FBAngular',
    'color.picker',
    //'ngLodash',
    'angularMoment',
    'appAccess',

    // Twelfthman modules
    'ngKomondorEditor',
    'ngKomondorMediaLibrary',
    'ngKomondorAccess',
    'ngKomondorTargetedImagery',
    'komondor.multitenancy',

    // App modules
    'datasheet',
    'presentation',
    'filters.date',
    'email',
    'color-picker-swatches',
    'floating-box-component',
    'link-component',
    'editorComponents',
    'helpCentre',
    'ngclipboard',
    'task',
    'IsMobile',
    'AppUuid',
    'presentation.analytics'
])

.config(function (resourceStateProvider)
{
    resourceStateProvider.stateOptions.Asset.deleteCheckDependencies = true;
})

.config(function ($rootScopeProvider)
{
    $rootScopeProvider.digestTtl(20);
})

.config(function (DSProvider)
{
    DSProvider.defaults.basePath = '/api/';
})

.config(function ($httpProvider)
{
    $httpProvider.useApplyAsync(true);
})

.config(function ($httpProvider, $windowProvider, appUuidProvider)
{
    var $window = $windowProvider.$get();
    var appUuid = appUuidProvider.$get();

    if ($window.present && $window.present.link_id) {
        $httpProvider.defaults.headers.common['X-Link-Id'] = $window.present.link_id;
    }

    if (appUuid()) {
        $httpProvider.defaults.headers.common['X-App-Uuid'] = appUuid();
    }
})

.config(function ($locationProvider)
{
    $locationProvider
        .html5Mode(false)
        .hashPrefix('!');
})

.run(function() {
    // Overwrite the angular.version object
    angular.version = {};
})

// .config(function ($qProvider)
// {
//    // $qProvider.errorOnUnhandledRejections(false);
// })

.run(function ($window, localStorageValid, appUuid, $rootScope, $timeout) {

    $timeout(() => {

        if (!localStorageValid()) {
            return;
        }

        if ($rootScope.offline) {
            return;
        }

        if ($rootScope.offline_edit) {
            return;
        }

        var localStorage = $window.localStorage;

        //Duplicated tab, need to refresh to get new app_uuid
        if (appUuid() === localStorage.appUuid) {
            $window.location.reload();
        }

        localStorage.appUuid = appUuid();
    });
})

// Stop them from accessing admin states if not logged in
.run(function ($rootScope, $state, kdAuth, loginState)
{
    $rootScope.$on('kd:403', function ($event, data)
    {
        if (data === 'Not logged in.') {
            kdAuth.setUser(null);
            $state.go(loginState());
        }
    });
})

// Stop them from accessing admin states if not logged in
.run(function ($rootScope, $state, auth, kdGate, loginState, kdAuth)
{
    $rootScope.$on('$stateChangeStart',
    function (event, toState, toStateParams)
    {
        if (!auth.isLoggedIn() && !/^guest/.test(toState.name)) {
            event.preventDefault();
            return $state.go(loginState());
        }

        const allowedToView = !toState.data ? false : toState.data.guestAccess;

        if (auth.isGuest() && !/^guest/.test(toState.name) && !allowedToView) {
            event.preventDefault();
            return $state.go('guest.forbidden');
        }

        if (kdAuth.user && kdAuth.user.is_prerender) {
            if ( !/admin.editor.slide/.test(toState.name) && !/admin.editor.presentation.print/.test(toState.name)) {
                kdAuth.setUser(null);
                $state.go(loginState());
            }
        }

        if (auth.isGuest() &&
            !$rootScope.offline &&
            /^admin.editor.presentation/.test(toState.name) &&
            !/guest.forbidden/.test(toState.name) &&
            toStateParams.presentationMode !== 'slideshow')
        {
            event.preventDefault();
            return $state.go('guest.forbidden');
        }

        const stateGates = {
            'admin.datasheets': 'data',
            'admin.clients': 'clients',
            'admin.contacts': 'contacts',
            'admin.icons': 'icon-repository',
            'admin.audit-logs': 'audit-logs',
            'admin.media': 'media',
            'admin.users': 'user-management.users',
            'admin.roles': 'user-management.roles',
            'admin.actions': 'user-management.permissions',
            'admin.themes': 'theme',
            'admin.domains': 'domain-management',
            'admin.companies': 'domain-management',
            'admin.help-centre': 'help-centre',
        };

        for (let st in stateGates) {
            const gate = stateGates[st];

            if (toState.name.indexOf(st) === 0) {
                if (kdGate.denies(gate)) {
                    event.preventDefault();
                    return $state.go('guest.forbidden');
                }
            }
        }
    });
})

.run(function (
    $rootScope,
    $timeout,
    targetedImageryConfig,
    targetedVideoConfig,
    mediaLibraryTaggingConfig,
    kdAuth,
    auth,
    kdGate,
    forceChangePassword,
    twoFaSetup,
    mediaLibraryUploadConfig,
    automaticLoadResourcePickerConfig,
    resetPasswordConfig
) {
    targetedImageryConfig.types.message = 'Targeted Message';
    mediaLibraryUploadConfig.queueLimit = 25;
    const changeConfig = function()
    {
        targetedImageryConfig.allowEdit = kdGate.allows('targeted-imagery.edit');
        targetedImageryConfig.allowMake = kdGate.allows('targeted-imagery.make');

        targetedVideoConfig.allowEdit = kdGate.allows('targeted-video.edit');
        targetedVideoConfig.allowMake = kdGate.allows('targeted-video.make');

        mediaLibraryTaggingConfig.allowEdit = kdGate.allows('media.tag');
        automaticLoadResourcePickerConfig.automaticLoad = true;

        $timeout(() => {
            if (kdAuth.user && !auth.isGuest()){

                if (kdAuth.user.must_reset_password) {
                    forceChangePassword.showModal();
                }

                if (kdAuth.user.must_setup_two_fa) {
                    twoFaSetup.showModal(true);
                }
            }
        });
    };

    if (kdAuth.user)
    {
        changeConfig();
    }

    resetPasswordConfig.resetUrlEnabled = false;

    $rootScope.$on('kdAuth:userChanged', changeConfig);
})

.run(function ($rootScope, kdGate, presentationState)
{
    // Allow to user in templates, where can't user kdGate directive.
    // For example: ng-show="model.type == 'presentation' && kdGate.check('presentations.edit-template')">
    $rootScope.kdGate = kdGate;

    // Bind presentationState to rootScope
    $rootScope.presentationState = presentationState;
})

.run(function ($http, ajaxHeaders)
{
    angular.extend($http.defaults.headers.common, ajaxHeaders);
})

.run(function (DSHttpAdapter)
{

    DSHttpAdapter.defaults.deserialize = function (resource, data)
    {
        var result = data;
        resource.meta = {};

        if (result && 'data' in result) {
            result = data.data;
        }

        //Getting metadata and rows
        if (result && 'meta' in result && 'rows' in result) {
            resource.meta = result.meta || {};
            result = result.rows;
        }

        if (data.config.method === 'GET') {

            var count = resource.meta.count || data.headers('x-total-count');
            if (angular.isDefined(count)) {
                resource.getCount = () => {
                    return Number(count);
                };
            }

            var tags = resource.meta.tags || JSON.parse(data.headers('x-tags'));
            if (tags) {
                resource.tags = tags || [];
            }

            if (data.headers('x-filters')) {
                resource.filters = resource.meta.filters || JSON.parse(data.headers('x-filters')) || [];
            }
        }

        return result;
    };
})

/**
 * Disable backspace button on non content editable html tags
 * to prevented backspace navigation.
 */
.run(function ($document)
{
    $document.on('keydown', function (e)
    {
        if (e.which !== 8) { //8 - Backspace
            return;
        }

        var nodeName = e.target.nodeName.toLowerCase();
        if (e.target.isContentEditable === true || nodeName === 'input' || nodeName === 'textarea' ) {
            return;
        }

        e.preventDefault();
    });
})

.config(function (NotificationProvider)
{
    NotificationProvider.setOptions({
        positionY: 'bottom',
        startTop: 15,
        startRight: 15,
        verticalSpacing: 10,

        // Show only last 5 message to not flood whole screen
        maxCount: 5
    });
})

.config(function (IsMobileProvider)
{
    IsMobileProvider.check();
})

.value('quietMode', false)

.decorator('resourceIcons', ['$delegate', function ($delegate)
{

    $delegate.template = 'material-icon material-icon-slide';
    $delegate.master = 'material-icon material-icon-slide';
    $delegate.presentation = 'material-icon material-icon-presentation';
    $delegate.datasheet = 'material-icon material-icon-datasheet';
    $delegate.client = 'material-icon material-icon-client';
    $delegate.contact = 'material-icon material-icon-account-circle';

    $delegate['grid-template'] = 'icon-grid-slide';
    $delegate['grid-master'] = 'icon-grid-slide';
    $delegate['grid-presentation'] = 'icon-grid-presentation';
    $delegate['grid-client'] = 'icon-grid-client';
    $delegate['grid-contact'] = 'icon-grid-contact';
    $delegate['grid-folder'] = 'icon-grid-folder';
    $delegate['grid-image'] = 'icon-grid-image';

    return $delegate;
}]);
